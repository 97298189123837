<template>
  <div>
    <b-modal id="modal-add-stock" title="Agregar Stock">
      <b-form-group label="Nombre">
        <b-form-input v-model="form.name" type="text" readonly></b-form-input>
      </b-form-group>
      <b-form-group label="Cantidad">
        <b-form-input
          id="drug__quantity"
          v-model="form.quantity"
          type="number"
          required
          placeholder="Ingresa la cantidad"
        ></b-form-input>
      </b-form-group>

      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="addStock()">
          Guardar
        </b-button>
      </template>
    </b-modal>

    <div class="row">
      <div class="col-md-12">
        <b-card border-variant="primary">
          <b-card-text>
            <div class="row">
              <div class="col-md-12">
                <b-table
                  :fields="fields"
                  :items="drugs"
                  :tbody-tr-class="rowClass"
                >
                  <template #cell(actions)="data">
                    <b-button
                      size="sm"
                      class="mb-2 mr-1"
                      variant="primary"
                      v-b-tooltip.hover
                      title="Agregar Stock"
                      v-b-modal.modal-add-stock
                      @click="launchDrugModal(data.item)"
                    >
                      <b-icon icon="plus" aria-hidden="true"></b-icon>
                    </b-button>
                    <b-button
                      size="sm"
                      class="mb-2 mr-1"
                      variant="primary"
                      v-b-tooltip.hover
                      title="Vender medicamento"
                    >
                      <b-icon icon="cash" aria-hidden="true"></b-icon>
                    </b-button>
                  </template>
                </b-table>
              </div>
            </div>
          </b-card-text>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  data() {
    return {
      form: { quantity: 1, drug: "", name: "" },
      drugs: [],
      fields: [
        { key: "name", label: "Nombre" },
        { key: "batch", label: "Lote" },
        { key: "lab_name", label: "Laboratorio" },
        { key: "expiration_date", label: "Fecha expiración" },
        { key: "price", label: "Precio Unitario" },
        { key: "quantity", label: "En Stock" },
        { key: "actions", label: "Acciones" }
      ]
    };
  },
  methods: {
    addStock() {
      ApiService.post("drug/stock/update/", this.form).then(() => {
        this.getDrugs();
      });
    },
    calculateAlertExpirationDate(dateString) {
      console.log("jeje");
      const inputDate = new Date(dateString);
      const currentDate = new Date();
      const futureDate = new Date(
        currentDate.getTime() + 30 * 24 * 60 * 60 * 1000
      );
      return inputDate < futureDate;
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (this.calculateAlertExpirationDate(item.expiration_date) === true)
        return "table-danger";
    },
    launchDrugModal(item) {
      this.form.name = item.name;
      this.form.drug = item.id;
    },
    getDrugs() {
      ApiService.get("drug").then(res => {
        this.drugs = res.data;
      });
    },

    selectSpecialty(selectedOption) {
      this.form.doctor = null;
      this.doctors = [];

      ApiService.get(
        "user-specialty/search/doctor",
        `?specialty=${selectedOption.id}`
      ).then(res => {
        this.doctors = res.data;
      });
    },

    saveAppointment() {
      let appointment = this.form;
      appointment.doctor = this.form.doctor.id;
      appointment.specialty = this.form.specialty.id;
      appointment.person = this.$route.query.person;
      ApiService.post("appointment/", appointment).then(res => {
        this.makeToast("success");
        // Get appointment data
        ApiService.get(`appointment/${res.data.id}/print`).then(res2 => {
          this.axios.post("http://localhost:3030/", res2.data);
          this.axios.post("http://localhost:3030/", res2.data);
          this.axios.post("http://localhost:3030/", res2.data);
          setTimeout(() => {
            this.$router.push({ name: "clinic_person_search" });
          }, 1000);
        });
      });
    },

    makeToast(variant = null) {
      this.$bvToast.toast("Éxito al guardar", {
        title: `Todo bien`,
        variant: variant,
        solid: true
      });
    },
    getCurrentDateTimeFormat() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear();
      let hour = String(today.getHours()).padStart(2, "0");
      let minutes = String(today.getMinutes()).padStart(2, "0");

      let formatted = yyyy + "-" + mm + "-" + dd + "T" + hour + ":" + minutes;
      console.log(formatted);
      //this.form.hour_of_service = formatted;
    }
  },
  mounted() {
    this.getCurrentDateTimeFormat();
    this.getDrugs();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Farmacia" },
      { title: "Lista de medicamentos" }
    ]);
  }
};
</script>

<style scoped>
.card-header {
  padding: 1rem 2.25rem;
}
</style>
